import React from 'react';
import styled, { css } from 'styled-components';
import Snowfall from 'react-snowfall';

import DownloadApkButton from '@/components/DownloadApkButton';
import Preloader from '@/components/Preloader';
import { colors, getTheme } from '@/constants/theme';
import { useIsHideLayout } from '@/hooks/useIsHideLayout';

import Header from './components/Header';
import Footer from './components/Footer';
import DealerWebHeader from './components/DealerWebHeader';
import DealerWebFooter from './components/DealerWebFooter';

interface Props {
  className?: string;
  children?: React.ReactNode;
  isPreloaderHidden?: boolean;
  isPrint?: boolean;
  isPurchasePage?: boolean;
  withoutFooterTopBottom?: boolean;
  withoutBottomFooter?: boolean;
}

function Layout({
  className,
  children,
  isPrint,
  isPreloaderHidden,
  isPurchasePage,
  withoutFooterTopBottom,
  withoutBottomFooter,
}: Props) {
  const theme = getTheme();
  const isLayoutHidden = useIsHideLayout();

  switch (theme) {
    case 'main':
      return (
        <Container className={className} isPrint={isPrint}>
          <Preloader hidden={isPreloaderHidden} />
          {!isLayoutHidden && <DownloadApkButton />}
          {!isLayoutHidden && <Header />}
          <Main>{children}</Main>
          {!isLayoutHidden && (
            <Footer
              isPurchasePage={isPurchasePage}
              withoutFooterTopBottom={withoutFooterTopBottom}
              withoutBottomFooter={withoutBottomFooter}
            />
          )}
        </Container>
      );

    case 'zeekr':
      return (
        <Container className={className} isPrint={isPrint}>
          <Preloader hidden={isPreloaderHidden} />
          <DealerWebHeader />
          <Main>{children}</Main>
          <DealerWebFooter />

          <Snowfall
            snowflakeCount={50}
            speed={[0, 1.5]}
            wind={[-1, 2]}
            radius={[0, 4]}
            color={colors.blue900}
            style={{ position: 'fixed' }}
          />
        </Container>
      );

    default:
      return (
        <Container className={className} isPrint={isPrint}>
          <Preloader hidden={isPreloaderHidden} />
          <DealerWebHeader />
          <Main>{children}</Main>
          <DealerWebFooter />
        </Container>
      );
  }
}

export default Layout;

const Container = styled.div<{ isPrint?: boolean }>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(props) => props.theme.white};
  overflow: clip;

  ${({ isPrint }) =>
    isPrint &&
    css`
      @media print {
        display: none;
      }
    `}
`;

const Main = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;
